.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  background: #f1f1f1;
  margin: 20px 0 20px 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
  color: red;
}

.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: block;
  color: red;
}
