body {
  margin: 10vh;
  font-family: Poppins, Sans-serif;
  font-weight: 300;
  color: white;
  align-items: center;
  background-color: #214063;
  background-image: url(/public/assets/images/thermovault-white-transparant.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  height: 80vh;
}
