.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  height: 300px;
  background: #f1f1f1;
  margin: 20px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.tabs_block {
  display: flex;
}

.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active_tab  {
  background: white;
  border-bottom: 1px solid transparent;
}

.active_tab::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

.tabs_block button {
  border: none;
}

.content_container {
  flex-grow : 1;
}

.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: block;
  color: black;
}

.content button {
  display: block;
  font-size: 25px;
  padding: 10px;
}
