.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

.App-link {
  color: white;
  align-self: end;
  font-size: 2vh;
}
